import React from 'react'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
const { slugify } = require('../../utility/utils');

const CorsoTeoria = ({ pageContext: { locale } })  => {
  const { corsobase } = useStaticQuery(
    graphql`
      query {
        corsobase : contentfulCorsoBaseScrittoTestata(contentful_id: {eq: "1rEkoRywkIXeT9HBRtuOQL"}, node_locale: {eq: "it"}) {
            titolo
            node_locale
            lezioni {
              titolo
            }
            copertina {
              description
              fluid(maxWidth:1240, quality:70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            corpoDelTesto {
              childMarkdownRemark {
                excerpt(pruneLength: 200)
                html
              }
            }
          }
        }
    `
  ); 
  const { titolo, copertina, corpoDelTesto, lezioni } = corsobase;
  return (
    <Layout locale={locale}>
       <SEO title={titolo} />
       <div className="section blog">
            <div className="row">
                <div className="col-12">
                    <Img fluid={copertina.fluid}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <h1 className="pagetitle">{titolo}</h1>
                    <div className="section__text blog__text" dangerouslySetInnerHTML={{__html: corpoDelTesto.childMarkdownRemark.html}} />
                    <h2 name="indice" className="pagetitle">Lezioni</h2>
                    {lezioni.map((item,index) => {
                        return (
                            <div key={index} className="blog__title">
                                <a title={`lezione ${index+1}`} href={`/corsi/online/${slugify(item.titolo)}/`}>{item.titolo}</a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </Layout>
  )
}
export default CorsoTeoria
